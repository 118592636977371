<template>
  <div>
    <!-- Hero -->
    <base-page-heading title="Lucrari">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Lucrari</b-breadcrumb-item>
          <b-breadcrumb-item active>Lista</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <!-- Page Content -->
    <div class="content">
      <base-block title="Lista de lucrari" v-bind:subtitle="'Numar de lucrari: '+workNumber">
        <b-table-simple responsive bordered striped table-class="table-vcenter">
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="d-sm-none" style="width: 80%;">Lucrare</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 15%;">Data</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 15%;">Nr. inreg.</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 25%;">Nume client</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 20%;">Telefon</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 15%">Status </b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%"></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="works.length <= 0 && loaded">
              <b-td class="d-md-none text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
              <b-td colspan="6" class="d-none d-sm-table-cell text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
            </b-tr>
            <b-tr v-for="work in works" :key="work._id">
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-dark font-weight-light">{{ getDateStr(work.node.createdAt) }}</span>
              </b-td>
              <b-td class="d-sm-none" style="width: 15%;">
                <div class="text-center mb-1">
                  <span class="font-weight-bold font-size-small text-uppercase" v-bind:class="'text-'+work.node.statusStyle">
                    {{ work.node.statusLabel }}
                  </span>
                </div>
                <b-row>
                  <b-col class="col-6 mb-2 font-weight-bold">
                    {{ getDateStr(work.node.createdAt) }}
                  </b-col>
                  <b-col class="col-6 mb-2 text-right font-weight-bold">
                    {{ getCode(work.node.code) }}
                  </b-col>
                </b-row>
                <div><span class="text-primary font-weight-bolder">{{ work.node.clientName }}</span></div>
                <b-row>
                  <b-col class="col-6 mt-2">
                    {{ work.node.clientPhone }}
                  </b-col>
                  <b-col class="col-6 mt-2 text-right">
                    {{ work.node.clientPhone }}
                  </b-col>
                </b-row>
                <div class="mt-2 text-right">
                  <router-link class="align-items-center justify-content-between btn btn-alt-primary btn-sm"  v-bind:to="getDetailUrl(work.node.id)">
                    <i class="fa fa-fw fa-info-circle"></i> detalii
                  </router-link>
                </div>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-dark font-weight-bolder">{{ getCode(work.node.code) }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-primary font-weight-bolder">{{ work.node.clientName }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-dark">{{ work.node.clientPhone }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                <span class="font-weight-bold" v-bind:class="'text-'+work.node.statusStyle">{{ work.node.statusLabel }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                <router-link class="align-items-center justify-content-between btn btn-alt-primary btn-sm" v-bind:to="getDetailUrl(work.node.id)">
                  <i class="fa fa-fw fa-info-circle"></i> detalii
                </router-link>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </base-block>
    </div>
    <infinite-loading @infinite="loadMore" ref="infiniteLoading">
      <div slot="spinner"><b-spinner variant="primary" label="Loading..."></b-spinner></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import {ITEM_NUMBER_PER_PAGE} from "@/constants/settings";
import {fleshErrorMessage} from "@/error/server-error";
import {SERVICE_WORK_LIST} from "@/constants/service-model-graphql";
import InfiniteLoading from "vue-infinite-loading";
import {getCode, getDetailUrl} from "../../../constants/helpers/service-helper";
import {getDateStr} from "../../../constants/helpers/common-helper";

export default {
  name: "ServiceDataList",
  components: {InfiniteLoading},
  data () {
    return {
      works: [],
      cursor: null,
      pageInfo: null,
      loaded: false,
      workNumber: 0,
    }
  },
  methods: {
    loadMore ($state) {
      this.$apollo.query({
        query: SERVICE_WORK_LIST,
        variables: {
          first: ITEM_NUMBER_PER_PAGE,
          after: (this.pageInfo) ? this.pageInfo.endCursor : null
        },
        fetchPolicy: 'no-cache',
      }).then((result) => {
        this.pageInfo = result.data.services.pageInfo;
        this.works.push(...result.data.services.edges);
        this.pageInfo.hasNextPage ? $state.loaded() : $state.complete();
        this.loaded = true;
        this.workNumber = result.data.services.totalCount;
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    getDateStr: getDateStr,
    getCode: getCode,
    getDetailUrl: getDetailUrl
  }
}
</script>